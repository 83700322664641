<template>
    <div class="wrap-box">
        <h3 class="cls-title">{{ info.topic }}></h3>
        <div class="wrap">
            <dyList :list="list"></dyList>
            <div class="page-box" v-show="total">
                <el-pagination :pager-count="5" :page-size="20" @current-change="changePage" background layout="prev, pager, next" :total="total"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import dyList from "@components/common/dy-list";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {
            dyList,
        },
        data() {
            return {
                list: [],
                info: {},
                total: 0,
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.info = this.$route.query;
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData(1);
        },
        methods: {
            changePage(s) {
                this.getData(s);
            },
            // 获取列表
            async getData(page) {
                let param = {
                    user_id: this.$y_getKey('userid'),
                    type: 0,
                    longitude: this.$y_getKey('lon'),
                    latitude: this.$y_getKey('lat'),
                };
                if (this.info.topic) {
                    param.topic = this.info.topic;
                }
                if (this.info.authed) {
                    param.authed = this.info.authed;
                }
                param.page = page || 1;
                let {
                    list,
                    total
                } = await this.$y_list("api/dymanic/lists", [], param);
                this.list = list;
                if (total != -1) {
                    this.total = total;
                }
            },
        },
        computed: {
            ...mapGetters(["s_getUser", "s_getCity"]),
        },
        watch: {
            s_getUser() {
                this.getData(1);
            },
            s_getCity() {
                this.getData(1);
            },
        },
    };
</script>

<style lang="less" scoped>
    .cls-title {
        padding: 20px;
        background: #fafafa;
    }
</style>